import React from "react";

function App() {
  return (
    <div className="h-screen flex items-center justify-center">
      <h1 className="uppercase">běhám a jím</h1>
    </div>
  );
}

export default App;
